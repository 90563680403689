import i18n from '@/i18n/i18n'

export default {
    'board_test_state_undefined_error': (board_id: string) => {
        return {
            type: 'Text',
            text: i18n.locale === 'ru'
                ? i18n.t('board_test_state_undefined_error_help', { 0: [ 'PressCodeSupportRu' ], 1: board_id })
                : i18n.t('board_test_state_undefined_error_help', { 0: [ 'PressCodeSupport' ], 1: board_id })
        }
    },
    'board_test_state_communication_error': (board_id: string) => {
        return {
            type: 'Text',
            text: i18n.locale === 'ru'
                ? i18n.t('board_test_state_communication_error_help', { 0: [ 'PressCodeSupportRu' ], 1: board_id })
                : i18n.t('board_test_state_communication_error_help', { 0: [ 'PressCodeSupport' ], 1: board_id })
        }
    },
    'board_test_state_handler_error': (board_id: string) => {
        return {
            type: 'Text',
            text: i18n.locale === 'ru'
                ? i18n.t('board_test_state_handler_error_help', { 0: [ 'PressCodeSupportRu' ], 1: board_id })
                : i18n.t('board_test_state_handler_error_help', { 0: [ 'PressCodeSupport' ], 1: board_id })
        }
    },
    'board_test_state_bot_domain_error': {
        type: 'View',
        view: () => import('./BoardStatusBotDomainError.vue'),
    },
    'board_test_state_api_error': {
        type: 'View',
        view: () => import('./BoardStatusApiError.vue'),
    },
}
