var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"channel-settings"},[_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'kick_deleted_accounts',
          'tags': [{ color: 'rgba(var(--a-primary), 1)', text: 'Beta' }]
        },
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'pm_reports',
        },
      }}})],1),_c('a-card',{staticClass:"mt-5"},[_c('select-input',{attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'approve',
          'options': _vm.approveTypeOptions,
          'clearable': false
        }
      }}}),(_vm.$store.state.channelsState.activeChannel.config.approve !== _vm.InviteLinkApproveType.None)?_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'hasMedia': true,
          'key': 'auto_accepted_user_message',
          'availableButtonsTypes': _vm.pmButtons,
          'base-api-url': 'api.presscode.app/cabinet',
          'targetToUpload': function () {
            return {
              'target': 'target',
              'target_id': _vm.$store.getters.getBoardById(_vm.$route.params.id).board
            }
          },
          'buttonsHelpView': _vm.EditorButtonsHelpView,
        }
      }}}):_vm._e(),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'hasMedia': true,
          'key': 'goodbuy_message',
          'availableButtonsTypes': _vm.pmButtons,
          'base-api-url': 'api.presscode.app/cabinet',
          'targetToUpload': function () {
            return {
              'target': 'target',
              'target_id': _vm.$store.getters.getBoardById(_vm.$route.params.id).board
            }
          },
          'buttonsHelpView': _vm.EditorButtonsHelpView,
          'translationParams': [_vm.$store.getters.getBoardById(_vm.$route.params.id).title]
        }
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }