
















































































import { InputSetups } from '@/mixins/input-setups'
import SaveConfigPanel from '@/components/SaveConfigPanel.vue'
import PageTitle from '@/components/PageTitle.vue'
import Slots from '@/components/Slots.vue'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'
import { InviteLinkApproveType } from './InviteLinks/types'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'

@Component({
  components: {
    Slots,
    SaveConfigPanel,
    PageTitle,
    ConfigField
  },
  data() {
    return {
      EditorButtonsHelpView,
      InviteLinkApproveType
    }
  }
})
export default class ChannelSettings extends Mixins<InputSetups, UseFields>(InputSetups, UseFields) {
  get approveTypeOptions(): Array<SelectOptionData> {
    return Object.values(InviteLinkApproveType).map((type) => {
      return {
        label: this.$t(`link_approve_type_${ type.toLowerCase() }`).toString(),
        value: type
      }
    })
  }
}
