

























































import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'

@Component
export default class SaveConfigPanel extends Vue {
  isNavOpen = false

  @Prop() avatar!: string

  @Prop() clickCallback!: Function

  get isNeededWindowWidth(): boolean {
    return parseInt(this.$store.state.AdminTemplate.windorWidth) < 930
  }
}
