




































































































































type WeekDaysFrontend = Record<number, {
  slots: Array<Slot & { index: number }>
}>

import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { Slot } from '@/includes/types/Board.types'
import { InputSetups } from '@/mixins/input-setups'
import PageTitle from '@/components/PageTitle.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

import { Component, Mixins } from 'vue-property-decorator'
import moment from 'moment'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    PageTitle,
    EmptyData,
    ConfigField
  }
})
export default class Slots extends Mixins<InputSetups, UseFields>(InputSetups, UseFields) {

  addSlotModalVisible = false

  activeWeekDay: number | null = null

  slotModel = this.newSlotFabric

  slotModalType: 'new' | 'edit' = 'new'

  get weekDaysFrontend(): WeekDaysFrontend {
    const weekDaysFrontend: WeekDaysFrontend = {
      1: { slots: [] },
      2: { slots: [] },
      3: { slots: [] },
      4: { slots: [] },
      5: { slots: [] },
      6: { slots: [] },
      7: { slots: [] }
    }

    this.$store.state.channelsState.activeChannel?.config.slots
      .forEach((value, index) => {
        weekDaysFrontend[value.active_day_of_week].slots.push({ ...value, index })
      })

    return weekDaysFrontend
  }

  get templateOptions(): Array<SelectOptionData> {
    return []
  }

  get newSlotFabric(): Slot & { index?: number } {
    return {
      time: '',
      ads_only: false,
      allow_styles: [],
      active_day_of_week: 1
    }
  }

  get modalTitle(): string {
    if (this.slotModalType === 'new') return this.$t('slot_modal_create').toString()
    if (this.slotModalType === 'edit') return this.$t('slot_modal_edit').toString()
    throw new Error('Unknown slotModalType value')
  }

  resetSlots():void {
    this.$confirm({
      title: this.$t('reset_slots_warning').toString(),
      okText: this.$t('accept').toString(),
      cancelText: this.$t('reject').toString(),
      onOk: () => {
        this.$store.commit('reset_slots')
        this.$store.dispatch('save_active_channel_config', this.$route)
      },
    })
  }

  copySlots():void {
    this.$store.dispatch('set_copied_slots', this.$store.state.channelsState.activeChannel?.config.slots)
      .then(() => successNotification(this.$t('slots_copied').toString()))
  }

  pasteSlots():void {
    const localSlotsBackup = cloneDeep(this.$store.state.channelsState.activeChannel?.config.slots)

    this.$store.dispatch('set_slots_to_config', this.$store.state.channelsState.copiedSlots)

      .then(() => {
        this.$confirm({
          title: this.$t('save_channel_settings_title').toString(),
          onOk: () => {
            this.$store.dispatch('save_active_channel_config', this.$route)
          },
          onCancel: () => {
            this.$store.dispatch('set_slots_to_config', localSlotsBackup)
          },
          okText: this.$t('accept').toString(),
          cancelText: this.$t('reject').toString()
        })
      })
  }

  formatSlotTime(time: string): string {
    return moment(time, 'HH:mm').format('HH:mm')
  }

  handleRemoveSlot(slot: Slot & { index: number }): void {
    this.$store.commit('remove_slot', slot.index)
    this.$store.dispatch('save_active_channel_config', this.$route)
  }

  editSlot(day: string, slot: Slot): void {
    this.slotModalType = 'edit'
    this.slotModel = { ...slot }
    this.addSlotModalVisible = true
  }

  editExistedSlot(slot: Slot & { index?: number }): void {
    this.$store.commit('update_slot', { itemIndex: slot.index, slot })
    this.$store.dispatch('save_active_channel_config', this.$route)
      .finally(() => {
        this.addSlotModalVisible = false
      })
  }

  slotExists(slot: Slot): boolean {
    return this.$store.state.channelsState.activeChannel?.config.slots.some(s => s.time === slot.time && s.active_day_of_week === slot.active_day_of_week)
  }

  handleOkButtonClick(): void {
    if (this.slotModalType === 'new') {
      this.handleAddSlotButton()
    } else {
      if (this.slotModel.index !== undefined) {
        const slotToEdit: Slot = this.$store.state.channelsState.activeChannel?.config.slots[this.slotModel.index]

        if (slotToEdit.time === this.slotModel.time) {
          this.editExistedSlot(this.slotModel)
        } else {
          if (!this.slotExists(this.slotModel)) {
            this.editExistedSlot(this.slotModel)
          } else {
            errorNotification(this.$t('slot_exists_error').toString())
          }
        }
      }
    }
  }

  openAddSlotModal(active_day_of_week: string): void {
    this.slotModalType = 'new'
    this.activeWeekDay = Number(active_day_of_week)
    this.addSlotModalVisible = true
  }

  handleModalCancel(): void {
    this.slotModel = { ...this.newSlotFabric }
    this.activeWeekDay = null
  }

  handleAddSlotButton(): void {
    if (this.activeWeekDay) {

      const resObject: Slot = {
        ...this.slotModel,
        active_day_of_week: this.activeWeekDay
      }

      if (!this.slotExists(resObject)) {
        this.$store.commit('add_slot_to_channel', resObject)
        this.$store.dispatch('save_active_channel_config', this.$route)
          .finally(() => {
            this.addSlotModalVisible = false
          })
      } else {
        errorNotification(this.$t('slot_exists_error').toString())
      }
    } else {
      errorNotification('__Active weekday is null')
    }
  }

  cardTitle(dayOfWeek: string): string {
    const day = Number.parseInt(dayOfWeek)

    switch (day) {
      case 1:
        return this.$t('monday').toString()
      case 2:
        return this.$t('tuesday').toString()
      case 3:
        return this.$t('wednesday').toString()
      case 4:
        return this.$t('thursday').toString()
      case 5:
        return this.$t('friday').toString()
      case 6:
        return this.$t('saturday').toString()
      case 7:
        return this.$t('sunday').toString()
      default:
        throw new Error('Unknown dayOfWeek')
    }
  }
}
