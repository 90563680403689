
































import Overlay from 'piramis-base-components/src/components/Overlay/Overlay.vue'

import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop, Ref, VModel } from 'vue-property-decorator'

@Component({
  components: {
    Overlay
  }
})
export default class TestStatusInfo extends Vue {
  @VModel() isPopupOpen!: boolean

  @Prop() info!: any

  @Ref('infoView') infoView!: Vue

  @Ref('infoText') infoText!: HTMLElement

  get modalWidth(): string | 520 {
    if (this.info.type === 'View') return '100%'
    return 520
  }

  get modalContentHeight(): Partial<CSSStyleDeclaration> | undefined {
    const largeStyles: Partial<CSSStyleDeclaration> = {
      height: `calc(100vh - 100px)`,
      overflowY: 'scroll'
    }

    if (this.infoView) {
      return largeStyles
    }

    if (this.infoText && this.infoText.offsetHeight > 750) {
      return largeStyles
    }

    return {
      height: 'fit-content'
    }
  }
}
