




































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import AListCustom from 'piramis-base-components/src/components/AListCustom/AListCustom.vue'
import AListCustomItem from 'piramis-base-components/src/components/AListCustom/AListCustomItem.vue'
import moment from 'moment'

@Component({
  components: {
    AListCustom,
    AListCustomItem
  },
  data() {
    return {
      moment
    }
  }
})
export default class ChannelLicenseInformation extends Vue {
  buyConfig = {
    name: 'Buy_channel', params: {
      id: this.$route.params.id, channelId: this.$route.params.channelId
    }
  }
  
  get channel() {
    return this.$store.state.channelsState.activeChannel
  }
}
