var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slots"},[_c('config-field',{attrs:{"title":_vm.$t('field_slots_title'),"help-message":_vm.$t('field_slots_help_message')}},[_c('div',{staticClass:"slot__actions text-right mr-2 mb-1",attrs:{"slot":"titleButton"},slot:"titleButton"},[(_vm.$store.state.channelsState.activeChannel.config.slots.length > 0)?_c('a-button',{attrs:{"icon":"copy","aria-label":"Скопировать все слоты"},on:{"click":_vm.copySlots}}):_vm._e(),(_vm.$store.state.channelsState.copiedSlots !== null)?_c('a-button',{staticClass:"ml-2",attrs:{"icon":"pull-request","type":"primary"},on:{"click":_vm.pasteSlots}}):_vm._e(),_c('a-button',{staticClass:"ml-2",attrs:{"icon":"rest","type":"danger","aria-label":"Удалить все слоты"},on:{"click":_vm.resetSlots}})],1)]),_c('div',{staticClass:"flex flex-wrap"},_vm._l((Object.entries(_vm.weekDaysFrontend)),function(ref,index){
var day = ref[0];
var dayValue = ref[1];
return _c('div',{key:index,staticClass:"w-full xs:w-1/2 md:w-2/6 xl:w-1/7 p-0 md:pr-1 mb-2 md:mb-0"},[_c('a-card',{staticClass:"slot_card",attrs:{"size":"small","title":_vm.cardTitle(day)}},[(dayValue.slots.length)?_c('ul',_vm._l((dayValue.slots),function(slot,index){return _c('li',{key:index,staticClass:"slot__item mb-2 last:mb-0"},[_c('div',{staticClass:"slot__body flex justify-between"},[_c('span',{staticClass:"slot__time cursor-pointer hover:opacity-0-7",on:{"click":function($event){return _vm.editSlot(day, slot)}}},[_vm._v(" "+_vm._s(_vm.formatSlotTime(slot.time))+" ")]),_c('div',{staticClass:"slot__actions"},[_c('a-tooltip',{attrs:{"title":_vm.$t('ad_posts_only')}},[(slot.ads_only)?_c('a-icon',{staticClass:"text-success cursor-pointer mr-1",attrs:{"type":"dollar"}}):_vm._e()],1),_c('a-icon',{staticClass:"text-danger hover:opacity-0-7",attrs:{"type":"delete"},on:{"click":function($event){return _vm.handleRemoveSlot(slot)}}})],1)])])}),0):_c('empty-data'),_c('template',{slot:"actions"},[_c('a-icon',{staticClass:"w-full btn-wrap",attrs:{"type":"plus"},on:{"click":function($event){return _vm.openAddSlotModal(day)}}})],1)],2)],1)}),0),_c('a-modal',{attrs:{"centered":"","title":_vm.modalTitle,"ok-text":_vm.$t('save'),"cancel-text":_vm.$t('cancel'),"after-close":_vm.handleModalCancel},on:{"ok":_vm.handleOkButtonClick},model:{value:(_vm.addSlotModalVisible),callback:function ($$v) {_vm.addSlotModalVisible=$$v},expression:"addSlotModalVisible"}},[_c('time-picker-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.slotModel,
          'key': 'time',
          'clearable': false,
          'time24hr': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.slotModel,
          'key': 'ads_only',
        }
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }