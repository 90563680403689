import { Style } from '@/includes/types/Post.types'
import { PmConfig, UserActivationActionFlow, UserActivationActionMessage } from './PmConfig.types'
import { MessageEditorWithMediaData } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import { LimitedSubscriptionConfig, LimitedSubscriptionType } from '@/includes/types/Channel.types'
import { InviteLinkApproveType } from '@/components/InviteLinks/types'
import { Property } from 'piramis-base-components/src/components/BotProperties/components/Properties/types'

export type Permissions = Record<'create' |
    'hold' |
    'view' |
    'edit' |
    'access_published' |
    'limit_published', boolean>

export type BoardAdminPermissions = Permissions | null

export type BoardAdmin = {
    user_id: number,
    username: string,
    name: string
    channel_ids: Array<number> | null
    limits: BoardAdminPermissions
}

export type BoardPermissions =
        "ChangeConfig" |
        "CreatePost" |
        "IgnoreHold" |
        "ViewOtherPosts" |
        "EditPosts" |
        "AccessPublishedPosts" |
        "IgnorePublishedPostsAccessLimits"

export type Board = {
    board: string
    title: string
    avatar: string
    id: string
    timezone: string
    lang: LangCode
    targets: Array<Target>
    weight: number
    admins: Array<BoardAdmin>
    test_state: TestBoardStateCode
    permissions: Array<BoardPermissions>
}

export type BoardAdmins = Pick<Board, 'admins'>

export enum TargetState {
    Board = 'Board',
    Log = 'Log',
    None = 'None',
}

export type Target = {
    title: string,
    id: number,
    license_time: string
    license_type: number
    avatar: string,
    state: TargetState
    members_count: number
    username: string
    test_state: TestChannelStateCode
    linked_chat_id: number | null
    limited_subscription_type: LimitedSubscriptionType
    licence_expired: boolean
    free_licence: boolean
    config: TargetConfig
} & TargetStatistics

export type TargetStatistics = {
    summary_stat?: SummaryStat
    detailed_stat: Array<DetailedStat>
}

export type SummaryStat = {
    first_enter: number
    first_leave: number
    all_enter: number
    all_leave: number
}

export type DetailedStat = {
    channel_id: number
    time: string
} & SummaryStat

export type TargetConfig = {
    auto_attack_mode_count: number
    auto_attack_mode_interval: number
    kick_deleted_accounts: boolean
    pm_reports: boolean
    replace_channel_forwards: boolean
    disable_join_to_group: boolean
    replace_channel_list: { type: 'Whitelist' | 'Blacklist', items: Array<string> }
    replace_user_forwards: boolean
    album_post_style: string
    media_post_style: string
    text_post_style: string
    slots: Array<Slot>
    goodbuy_message: Array<MessageEditorWithMediaData>
    limited_subscription_type: LimitedSubscriptionType
    limited_subscription_config?: LimitedSubscriptionConfig | null
    auto_accepted_user_message: Array<MessageEditorWithMediaData>
    approve: InviteLinkApproveType
    user_accept_action?: UserActivationActionFlow | UserActivationActionMessage | null
    user_reject_action?: UserActivationActionFlow | UserActivationActionMessage | null
}

export type BoardConfigPermissions =
    'ChangeConfig'
    | 'CreatePost'
    | 'IgnoreHold'
    | 'ViewOtherPosts'
    | 'EditPosts'
    | 'AccessPublishedPosts'
    | 'IgnorePublishedPostsAccessLimits'

export type BoardConfig = {
    config: NestedBoardConfig
    permissions: Array<BoardConfigPermissions>
    config_version: number
    features_version: number
    license_time: number
    license_type: number
} & Omit<Board, 'weight'>

export type NestedBoardConfig = {
    styles: Record<string, Style> | null
    pm_config: PmConfig
    editor_hold_time:number
    editor_max_post_access_time:number
    properties: Array<Omit<Property, 'group'>>
    labels: { [key: number]: string }
}

type LangCode = 'ru' | 'en'

export enum TestBoardStateCode {
    Success = 'Success',
    None = 'None',
    BotDomainError = 'BotDomainError',

    UndefinedError = 'UndefinedError',
    HandlerError = 'HandlerError',
    ApiError = 'ApiError',
    CommunicationError = 'CommunicationError',
}

export enum TestChannelStateCode {
    Success = 'Success',
    None = 'None',
    ChannelPermissionDeleteMessageError = 'ChannelPermissionDeleteMessageError',
    ChannelPermissionEditMessageError = 'ChannelPermissionEditMessageError',
    ChannelPermissionAddUsersError = 'ChannelPermissionAddUsersError',
    ConnectedGroupNotFoundError = 'ConnectedGroupNotFoundError',
    ConnectedGroupPermissionSendMessageError = 'ConnectedGroupPermissionSendMessageError',
    ConnectedGroupPermissionPinMessageError = 'ConnectedGroupPermissionPinMessageError',
    ConnectedGroupPermissionDeleteMessageError = 'ConnectedGroupPermissionDeleteMessageError',
    ConnectedGroupPermissionEditMessageError = 'ConnectedGroupPermissionEditMessageError',

    UndefinedError = 'UndefinedError',
    ApiError = 'ApiError',
    ChannelNotFoundError = 'ChannelNotFoundError',
    ChannelPermissionSendMessageError = 'ChannelPermissionSendMessageError',
}

export type ChannelSlots = {
    date: string
    slot: Slot
}

export type Slot = {
    time: string
    active_day_of_week: number
    allow_styles: Array<string>
    ads_only: boolean
}

