




































































































import { TargetState } from '@/includes/types/Board.types'
import PageTitle from '@/components/PageTitle.vue'
import { hasChangeConfigPermission } from '@/includes/PermissionHelper'
import TestStateMixin from '@/mixins/TestStateMixin'
import TestStatusInfo from '@/components/TestStatusInfo.vue'

import AListCustom from 'piramis-base-components/src/components/AListCustom/AListCustom.vue'
import AListCustomItem from 'piramis-base-components/src/components/AListCustom/AListCustomItem.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'
import ClipboardCopyMixin from 'piramis-base-components/src/logic/helpers/ClipboardCopyMixin'

import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import moment from 'moment'

@Component({
  components: {
    PageTitle,
    AListCustom,
    AListCustomItem,
    TestStatusInfo,
    HelpMessage
  },
  data() {
    return {
      TargetState,
      hasChangeConfigPermission,
      moment,
    }
  }
})
export default class ChannelDetailedInformation extends Mixins<UseFields, TestStateMixin, ClipboardCopyMixin>(UseFields, TestStateMixin, ClipboardCopyMixin) {
  get channel() {
    return this.$store.state.channelsState.activeChannel
  }

  get getChannelState(): string {
    if (this.channel.state === TargetState.Log) {
      return this.$t('target_state_log').toString()
    }

    return this.$t('target_state_board').toString()
  }

  handleTestChannelStatusClick(event: Event): void {
    if (this.buttonDisabled) {
      event.preventDefault()
    } else {
      this.testChannelStatus(this.$store.getters.getBoardById(this.$route.params.id).board, this.channel.id)
    }
  }
}
